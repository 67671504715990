@import "../../global.scss";

.intro {
  display: flex;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .container {
    flex: 0.5;
    overflow: hidden;

    .photo {
      width: 700px;
      height: 700px;
      margin-top: 20px;
      display: flex;
      background-color: #ffffff;
      border-radius: 20px;
      align-items: flex-end;
      justify-content: center;
      float: right;

      @include mobile {
        align-items: flex-start;
      }

      img {
        height: 100%;
        border-radius: 5px;

        @include mobile {
          height: 60%;
        }
      }
    }
  }

  .greeting {
    flex: 0.5;
    position: relative;

    .appeal {
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile {
        padding-left: 0;
        align-items: center;
      }

      h4 {
        font-size: 15px;
        margin: 10px 0;

        @include mobile {
          font-size: 7px;
        }
      }

      h6 {
        margin: 15px 0;

        @include mobile {
          margin-top: 10px;
          font-size: 10px;
        }
      }

      h2 {
        font-size: 40px;

        @include mobile {
          font-size: 20px;
        }
      }

      h3 {
        text-transform: uppercase;
        width: 50%;
        margin-top: 120px;
        animation: colortext  8s infinite;

        @keyframes colortext {
          0%{color: #01070c;}
          10%{color: #c29f33;}
          20%{color: #3e9264;}
          30%{color: #5ecc47;}
          40%{color: #b656e3;}
          50%{color: #43c1c1;}
          60%{color: #599000;}
          70%{color: #6f3f68;}
          80%{color: #feb256;}
          90%{color: #005179;}
          100%{color: #51eebf;}
        }

        @include mobile {
          text-transform: lowercase;
          width: 100%;
          margin-top: 13px;
        }
      }

      h4 {
        font-size: 20px;

        @include mobile {
          font-size: 15px;
        }

        span {
          font-size: inherit;
          color: rgb(164, 19, 50);
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      }

      @include mobile {
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 10px;
      }
    }
  }
}
