@import "./global.scss";

.app{
    height: 100vh;
    scrollbar-width: none;
    &::-webkit-scrollbar{
        display: none;
    }

    .sections {
        width: 100%;
        height: calc(100vh - 50px);
        background-color: rgb(251, 239, 255);
        position: relative;
        top: 70px;
        scroll-behavior: smooth;
        scrollbar-width: none; // for firefox
        &::-webkit-scrollbar{
            display: none;
        }

        > *{
            width: 100vw;
            height: calc(100vh - 20px);
            scroll-snap-align: start;
        }

        @include mobile {
            top: 100px;
            height: calc(100vh - 100px);
        }

        @include mobile-min {
            top: 200px;
            height: calc(100vh - 200px);
        }
    }
}
