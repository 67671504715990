@import "../../global.scss";

.certificate {
  display: flex;
  align-items: center;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
    height: 100%;

    @include mobile {
      zoom: 0.5;
      padding-top: 20px;
    }

    .mobile {
      height: 640px;
    }

    .desktop {
      background-color: rgb(196, 204, 248);
      border-radius: 10px 100px / 120px;
      width: 490px;
      margin: 30px;
      padding: 30px;

      .title {
        margin: 30px;
      }

      ul {
        width: 390px;

        li {
          width: 360px;
          background-color: lightyellow;
          border-radius: 30px;
          line-height: 50px;
          text-align: left;
          font-size: 20px;
          font-weight: 500;
          margin-left: 30px;
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
    height: 100%;

    .diploma {
      padding: 80px 20px 20px 20px;
      width: 650px;
      height: 500px;

      @include mobile {
        zoom: 0.5;
      }
    }
  }
}
