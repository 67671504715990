@import "../../global.scss";

.reviews {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .arrow {
    height: 50px;
    position: absolute;
    cursor: pointer;
    background-color: rgb(160, 67, 235);
    border-radius: 40px;

    @include mobile {
      height: 40px;
    }

    &.left {
      left: 100px;
      transform: rotateY(180deg);

      @include mobile {
        left: 10px;
      }
    }

    &.right {
      right: 100px;

      @include mobile {
        right: 10px;
      }
    }
  }

  .slider {
    height: 650px;
    left: 0;
    display: flex;
    position: absolute;
    transition: all 1s ease-out;

    @include mobile {
      height: 640px;
    }

    .container {
      width: 100vw;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .item {
        width: 320px;
        height: 120%;
        background-color: rgb(255, 255, 255);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 30px;

        @include mobile {
          width: 80%;
          height: 600px;
          margin: 15px 0;
          overflow: hidden;
        }

        .left {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            height: 90%;
          }
        }

        .center {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            height: 90%;

            @include mobile {
              height: 110%;
            }
          }
        }

        .right {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            height: 90%;
          }
        }
      }
    }
  }
}
