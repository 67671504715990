@import "../../global.scss";

.contacts {
  display: flex;
  flex-direction: column;

  @include mobile {
    display: flex;
    flex-direction: column;
  }

  .map {
    flex: 2;
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    overflow: hidden;

    img {
      height: 100%;
    }
  }

  .social {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .icon {
      font-size: 50px;

      @include mobile {
        font-size: 35px;
      }
    }
  }
}

.MuiPhoneInputDropdown {
  display: none;
}
