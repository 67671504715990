@import "../../global.scss";

.students {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @include mobile {
    justify-content: space-around;
    height: unset !important;
    margin-bottom: 40px;
  }

  h1 {
    font-size: 50px;
    margin-top: 30px;
    overflow: hidden;

    @include mobile {
      font-size: 25px;
      margin-bottom: 15px;
      padding: 3px;
      padding-bottom: 18px;
    }
  }

  .container {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      flex-direction: column;
      height: 100%;
      margin-top: 5px;
    }

    .mobile {
      @include mobile {
        display: flex;
        flex-direction: column;
        height: 495px;
      }
    }

    .card {
      height: auto;
      border-radius: 10px;
      box-shadow: 0px 0px 15px -8px black;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 20px;
      transition: all 1s ease;
      background-color: white;

      &.featured {
        width: 300px;
        height: 75%;
        margin: 5px 30px;
        cursor: pointer;
      }

      &:hover {
        transform: scale(1.1);
      }

      .top {
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          position: relative;
          height: 100%;
          width: 100%;
        }

        img {
          &.user {
            height: 250px;
            width: 200px;
            object-fit: cover;

            @include mobile {
              position: absolute;
              object-fit:cover;
              width: 100%;
              height: 150%;
              border-radius: 3px;
            }
          }
        }
      }

      .center {
        padding: 10px;
        //height: 100%;
        border-radius: 10px;
        color: #000000;
        white-space: pre-line;
        font-weight: bold;

        @include mobile {
          padding: 0;
          border-radius: unset;
          font-size: 10px;
          font-weight: bold;
          background-color: transparent;
        }
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @include mobile {
          border-radius: 10px;
          padding: 5px;
        }

        h3 {
          margin-bottom: 5px;

          @include mobile {
            font-size: 12px;
            overflow: hidden;
            padding: 1px;
          }
        }

        h4 {
          color: rgb(160, 67, 235);

          @include mobile {
            overflow: hidden;
            font-size: 8px;
            padding: 2px;
          }
        }
      }
    }
  }
}
