@import "../../global.scss";

.topbar {
  width: 100%;
  height: 70px;
  background-color: white;
  color: $mainColor;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;
  overflow: hidden;

  @include mobile {
    height: 100px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      @include mobile {
        flex-direction: column;
      }

      .logoimg {
        margin-right: 20px;

        @include mobile {
          display: none;
        }

        .logotype {
          width: 70px;
          height: 70px;
          cursor: pointer;

          @include mobile {
            margin-left: 40px;
          }
        }
      }

      .logo {
        font-size: 25px;
        font-weight: 700;
        text-decoration: none;
        color: inherit;
        margin-right: 40px;
        padding: 10px;

        @include mobile {
          font-size: 20px;
          margin-right: 0;
        }
      }

      .itemContainer {
        display: flex;
        align-items: center;
        margin-left: 30px;

        @include mobile {
          margin-bottom: 5px;
        }

        .icon {
          font-size: 18px;
          margin-right: 7px;
        }

        span {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }

    .right {

      .hamburger {
        width: 32px;
        height: 26px;
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 100%;
          height: 3px;
          background-color: $mainColor;
          transform-origin: left;
          transition: all 2s ease;
        }
      }
    }

    a {
      text-decoration: none;
      color: $mainColor;
      transition: all 1s ease;
    }
  }

  &.active {
    background-color: $mainColor;
    color: white;

    a {
      text-decoration: none;
      color: white;
    }

    .hamburger {

      span {
        &:first-child {
          background-color: white;
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          background-color: white;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
